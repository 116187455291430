<template>
  <acciones>
    <accion-primaria :label="$t('Editar')" icon="edit" :to="`mediciones/editar/${medicion.id}`" />

    <acciones-secundarias>
      <accion-eliminar @eliminar="eliminarMedicion(medicion)" :confirmacion="`¿Desea eliminar la medición?`" />
    </acciones-secundarias>
  </acciones>
</template>
<script>
import * as apiMediciones from "@/services/mediciones.js";
export default {
  props: {
    medicion: Object,
  },
  methods: {
    async eliminarMedicion(medicion) {
      await apiMediciones.eliminar(medicion.id);
      this.$root.$emit("userMessage", this.$t("Medicion eliminada"));
      this.$emit("medicionEliminada", medicion.id);
    },
  },
};
</script>
