<template>
  <page-listado :title="`${indicador.nombre}: ${$t('Mediciones')}`" con-busqueda>
    <template slot="toolbar">
      <b-button v-if="this.tipoOrigen == null" variant="primary" size="sm" to="mediciones/crear">
        <v-icon name="plus" width="16"></v-icon>
        {{ $t("Crear") }}
      </b-button>
      <b-button v-else variant="primary" size="sm" @click="importarDatasource">
        <v-icon name="refresh-cw" width="16"></v-icon>
        {{ $t("Actualizar datos") }}
      </b-button>
    </template>

    <tabla :mediciones="resultado.mediciones" @medicionEliminada="medicionEliminada"></tabla>

    <paginacion
      v-if="resultado.numeroPaginas > 1"
      :page-count="resultado.numeroPaginas"
      :page="resultado.pagina"
      :pageRange="3"
    ></paginacion>
  </page-listado>
</template>

<script>
import * as apiIndicadores from "@/services/indicadores";
import * as apiOrigenesDatos from "@/services/origenesDatos";
import * as apiMediciones from "@/services/mediciones";
import Tabla from "./Tabla";
export default {
  components: {
    Tabla,
  },
  data() {
    return {
      indicadorId: null,
      indicador: apiIndicadores.crear(),
      tiposOrigen: Object.freeze({ CHECKLIST: 1, INCIDENTES: 2 }),
      resultado: {
        mediciones: [],
        numeroPaginas: 0,
        pagina: 1,
      },
      tipoOrigen: null,
    };
  },
  methods: {
    async cargarIndicador() {
      this.indicador = await apiIndicadores.cargar(this.indicadorId);

      // Se obtiene el tipo de origen de datos
      if (this.indicador.tipoIndicador != 1) {
        const responseOrigen = await apiOrigenesDatos.cargar(this.indicador.origenDatosId);
        this.tipoOrigen = responseOrigen.tipoOrigen;
      }
    },
    async listar() {
      this.resultado = await apiMediciones.listar(this.indicadorId, this.$route.query);
    },
    async importarDatasource() {
      let medicionesCreadas = await apiIndicadores.importarDatasource(this.indicador);
      if (medicionesCreadas) {
        this.$emit("userMessage", "Se han importado las mediciones");
        this.listar();
      }
    },
    medicionEliminada(id) {
      this.listar();
    },
  },
  watch: {
    "$route.params": {
      handler: function () {
        this.indicadorId = this.$route.params.indicadorId;
        this.cargarIndicador();
        this.listar();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
