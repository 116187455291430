<template>
  <table class="listado table table-hover m-0 tabla-desktop">
    <thead class="bg-primary text-white">
      <th>{{ $t("Fecha") }}</th>
      <th>{{ $t("Causa") }}</th>
      <th>{{ $t("Zona") }}</th>
      <th>{{ $t("Valor") }}</th>
      <th class="th-acciones">{{ $t("Acciones") }}</th>
    </thead>
    <tbody>
      <tr v-for="medicion in mediciones" v-bind:key="medicion.id">
        <td>{{ medicion.fecha | fecha }}</td>
        <td>{{ medicion.causa }}</td>
        <td>{{ medicion.zona }}</td>
        <td>{{ medicion.valor }}</td>
        <td nowrap="nowrap" class="td-acciones">
          <botonera-acciones-tabla :medicion="medicion" @medicionEliminada="onMedicionEliminada" />
        </td>
      </tr>
    </tbody>
    <sin-registros :count="mediciones.length" :colspan="7"></sin-registros>
  </table>
</template>

<script>
import BotoneraAccionesTabla from "./BotoneraAccionesTabla.vue";

export default {
  props: {
    mediciones: Array,
  },
  methods: {
    onMedicionEliminada(id) {
      this.$emit("medicionEliminada", id);
    },
  },
  components: {
    BotoneraAccionesTabla,
  },
};
</script>
