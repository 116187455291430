<template>
  <div class="listado tabla-mobile">
    <filtros-listado-mobile>
      <filtro-activo field="activa" />
    </filtros-listado-mobile>

    <fila-compacta v-for="(medicion, idx) in mediciones" v-bind:key="idx" :titulo="medicion.fecha">
      <div>
        {{ $t("Fecha") }}: <span>{{ medicion.fecha }}</span>
        <br />
        {{ $t("Causa") }}: <span>{{ medicion.causa }}</span>
        <br />
        {{ $t("Zona") }}: <span>{{ medicion.zona }}</span>
        <br />
        {{ $t("Valor") }}: <span>{{ medicion.valor }}</span>
        <br />
      </div>

      <botonera-acciones-tabla slot="toolbar" :medicion="medicion" @medicionEliminada="onMedicionEliminada" />
    </fila-compacta>

    <h3 class="text-center" v-if="mediciones.length == 0">
      {{ $t("No hay mediciones") }}
    </h3>
  </div>
</template>

<script>
import BotoneraAccionesTabla from "./BotoneraAccionesTabla.vue";

export default {
  props: {
    mediciones: { type: Array, required: true },
  },
  data() {
    return {
      activa: 1,
    };
  },
  components: { BotoneraAccionesTabla },
  methods: {
    onMedicionEliminada(id) {
      this.$emit("medicionEliminada", id);
    },
  },
};
</script>
