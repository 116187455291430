<template>
  <div>
    <tabla-mobile :mediciones="mediciones" @medicionEliminada="onMedicionEliminada" />
    <tabla-desktop :mediciones="mediciones" @medicionEliminada="onMedicionEliminada" />
  </div>
</template>
<script>
import TablaMobile from "./TablaMobile.vue";
import TablaDesktop from "./TablaDesktop.vue";
export default {
  components: {
    TablaMobile,
    TablaDesktop,
  },
  props: {
    mediciones: Array,
  },
  methods: {
    onMedicionEliminada(id) {
      this.$emit("medicionEliminada", id);
    },
  },
};
</script>
